import * as Types from '../../../../__generated__/graphql';

import { gql } from '@apollo/client';
import { ReproductionCrRowsRowFragmentDoc } from './reproductionCrRowsRow.graphql';
import { ReproductionCrValueFragmentDoc } from './reproductionCrValue.graphql';
export type ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByEmployee_Fragment = { __typename: 'ReproductionCrRowsByEmployee', hashID: string, employeeRowNames: Array<{ __typename: 'Employee', id: string, firstName: string, middleName: string, lastName: string, number: string } | null>, total?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, other?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, rows: Array<{ __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null }> };

export type ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByDate_Fragment = { __typename: 'ReproductionCrRowsByDate', hashID: string, dateRowNames: Array<{ __typename: 'ReproductionCrDateValue', since?: string | null, till?: string | null } | null>, total?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, other?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, rows: Array<{ __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null }> };

export type ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByIntervalBetweenInseminations_Fragment = { __typename: 'ReproductionCrRowsByIntervalBetweenInseminations', hashID: string, intervalBetweenInseminationsRowNames: Array<{ __typename: 'ReproductionCrIntervalBetweenInseminationsValue', periodStart?: number | null, periodEnd?: number | null } | null>, total?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, other?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, rows: Array<{ __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null }> };

export type ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByInseminationScheme_Fragment = { __typename: 'ReproductionCrRowsByInseminationScheme', hashID: string, inseminationSchemeRowNames: Array<{ __typename: 'InseminationScheme', id: string, name: string, code: string, isDefault: boolean } | null>, total?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, other?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, rows: Array<{ __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null }> };

export type ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByStudCode_Fragment = { __typename: 'ReproductionCrRowsByStudCode', hashID: string, studCodeRowNames: Array<{ __typename: 'SemenSupplier', name: string } | null>, total?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, other?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, rows: Array<{ __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null }> };

export type ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByAnimalCycle_Fragment = { __typename: 'ReproductionCrRowsByAnimalCycle', hashID: string, animalCycleRowNames: Array<{ __typename: 'ReproductionCrRowByAnimalCycleValue', periodStart?: number | null, periodEnd?: number | null } | null>, total?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, other?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, rows: Array<{ __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null }> };

export type ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByBullBreed_Fragment = { __typename: 'ReproductionCrRowsByBullBreed', hashID: string, bullBreedRowNames: Array<{ __typename: 'Breed', name: string } | null>, total?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, other?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, rows: Array<{ __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null }> };

export type ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByBull_Fragment = { __typename: 'ReproductionCrRowsByBull', hashID: string, bullRowNames: Array<{ __typename: 'Bull', id: string, name: string, registrationNumber: string, breed: string, usdaNumber: string, retirementReason?: Types.BullRetirementReason | null, category: string, retiredAt?: string | null, bullState: Types.BullState, bullDateOfBirth?: string | null } | null>, total?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, other?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, rows: Array<{ __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null }> };

export type ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByDows_Fragment = { __typename: 'ReproductionCrRowsByDows', hashID: string, dowRowNames: Array<{ __typename: 'ReproductionCrRowByDowsValue', dow?: Types.Dow | null } | null>, total?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, other?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, rows: Array<{ __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null }> };

export type ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByInseminationNumber_Fragment = { __typename: 'ReproductionCrRowsByInseminationNumber', hashID: string, inseminationNumberRowNames: Array<{ __typename: 'ReproductionCrInseminationNumberValue', inseminationNumber?: number | null } | null>, total?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, other?: { __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null } | null, rows: Array<{ __typename: 'ReproductionCrRowsRow', hashID: string, cr?: number | null, pregnant?: number | null, notPregnant?: number | null, other?: number | null, abortions?: number | null, inseminations?: number | null, uncheckedInseminations?: number | null, uncheckedInseminationsPercent?: number | null, inseminationsPercent?: number | null, semenDosesTillPregnancyCount?: number | null }> };

export type ReproductionCrDetailedRowsByEntityFragment = ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByEmployee_Fragment | ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByDate_Fragment | ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByIntervalBetweenInseminations_Fragment | ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByInseminationScheme_Fragment | ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByStudCode_Fragment | ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByAnimalCycle_Fragment | ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByBullBreed_Fragment | ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByBull_Fragment | ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByDows_Fragment | ReproductionCrDetailedRowsByEntity_ReproductionCrRowsByInseminationNumber_Fragment;

export const ReproductionCrDetailedRowsByEntityFragmentDoc = gql`
    fragment ReproductionCrDetailedRowsByEntity on ReproductionCrDetailedRowsByEntity {
  __typename
  hashID
  total {
    ...ReproductionCrRowsRow
  }
  other {
    ...ReproductionCrRowsRow
  }
  rows {
    ...ReproductionCrRowsRow
  }
  ... on ReproductionCrRowsByDate {
    dateRowNames: rowNames {
      ...ReproductionCrValue
    }
  }
  ... on ReproductionCrRowsByStudCode {
    studCodeRowNames: rowNames {
      ...ReproductionCrValue
    }
  }
  ... on ReproductionCrRowsByInseminationNumber {
    inseminationNumberRowNames: rowNames {
      ...ReproductionCrValue
    }
  }
  ... on ReproductionCrRowsByAnimalCycle {
    animalCycleRowNames: rowNames {
      ...ReproductionCrValue
    }
  }
  ... on ReproductionCrRowsByBullBreed {
    bullBreedRowNames: rowNames {
      ...ReproductionCrValue
    }
  }
  ... on ReproductionCrRowsByEmployee {
    employeeRowNames: rowNames {
      ...ReproductionCrValue
    }
  }
  ... on ReproductionCrRowsByIntervalBetweenInseminations {
    intervalBetweenInseminationsRowNames: rowNames {
      ...ReproductionCrValue
    }
  }
  ... on ReproductionCrRowsByInseminationScheme {
    inseminationSchemeRowNames: rowNames {
      ...ReproductionCrValue
    }
  }
  ... on ReproductionCrRowsByBull {
    bullRowNames: rowNames {
      ...ReproductionCrValue
    }
  }
  ... on ReproductionCrRowsByDows {
    dowRowNames: rowNames {
      ...ReproductionCrValue
    }
  }
}
    ${ReproductionCrRowsRowFragmentDoc}
${ReproductionCrValueFragmentDoc}`;